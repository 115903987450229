import React, { Component } from "react";
import { Container } from "reactstrap";

export default class BgBlocks extends Component {
  render() {
    return (
      <>
      <div className="page-header header-filter">
        <div className="squares square1">
          <canvas className="squares canv" />
        </div>
        <div className="squares square2">
          <canvas className="squares canv" />
        </div>
        <div className="squares square3">
          <canvas className="squares canv" />
        </div>
        <div className="squares square4">
          <canvas className="squares canv" />
        </div>
        <div className="squares square5">
          <canvas className="squares canv" />
        </div>
        <div className="squares square6">
          <canvas className="squares canv" />
        </div>
        <div className="squares square7">
          <canvas className="squares canv" />
        </div>
      </div>
      <div className="main">
      <Container>
          <div className="content-center">
            {this.props.children}
          </div>
        </Container>
      </div>
      </>
    );
  }
  componentDidMount() {
    const boxes = [];
    document.querySelectorAll("canvas.squares.canv").forEach((canv) => {
      var ctx=canv.getContext("2d");
      var drops=[];
      var gradient=ctx.createLinearGradient(0,0,0,canv.offsetHeight);
      gradient.addColorStop(0,'#1d8cf8');
      gradient.addColorStop(1,'#3358f4');
      for(var x=0; x<(canv.width/12); x++) drops[x]=1;
      boxes.push({ctx:ctx,gradient:gradient,drops:drops});
    });
    setInterval(async () => {
      boxes.forEach((box) => {
        box.ctx.fillStyle="rgba(0,0,0,0.05)";
        box.ctx.fillStyle=box.gradient;
        box.ctx.fillRect(0,0,box.ctx.canvas.width,box.ctx.canvas.height);
        box.ctx.fillStyle="#3faefa";
        box.ctx.font="12px courier";
        for(var i = 0; i < box.drops.length; i++) {
          var text=["0","1"][Math.floor(Math.random()*["0","1"].length)];
          box.ctx.fillText(text,i*12,box.drops[i]*12);
          if(box.drops[i]*12 > box.ctx.canvas.height && Math.random() > 0.975)
            box.drops[i] = 0;
          box.drops[i]++;
        }
      });
    }, 70);
  }
}
