import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes.js";
import { toast, ToastContainer } from 'react-toastify';
// import { ContextProvider } from './views/components/Firebase/context.js';
import IndexNavbar from "./views/components/IndexNavbar.js";
import BgBlocks from "./views/components/BgBlocks.js";
import Footer from "./views/components/Footer.js";
import Firebase , { FirebaseContext } from './views/components/Firebase';
import "./assets/scss/principal.scss?v=1.1.0";
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ToastContainer hideProgressBar={true} position={toast.POSITION.BOTTOM_RIGHT} />
    {/* <ContextProvider> */}
      <BrowserRouter>
        <IndexNavbar />
        <div className="wrapper">
          <BgBlocks>
            <Routes/>
          </BgBlocks>
          <Footer />
        </div>
      </BrowserRouter>
    {/* </ContextProvider> */}
  </FirebaseContext.Provider>,
  document.getElementById("root")
);