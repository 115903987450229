import React from "react";
import { Route, Switch } from "react-router-dom";

import "./assets/scss/principal.scss?v=1.1.0";

import HomeView from "./views/Home.js";
import LandingPage from "./views/examples/LandingPage.js";
import SignInView from "./views/SignIn.js";
import SignUpView from "./views/SignUp.js";
import ProfileView from "./views/examples/ProfilePage.js";
import PasswordResetView from "./views/PasswordReset.js";

export const Routes  = () => (
    <Switch>
      <Route 
        path="/" 
        render={props => <HomeView {...props} />} 
        exact
      />
      <Route
        path="/landing-page"
        render={props => <LandingPage {...props} />}
      />
      <Route
        path="/sign-in"
        render={props => <SignInView {...props} />}
      />
      <Route
        path="/sign-up"
        render={props => <SignUpView {...props} />}
      />
      <Route
        path="/password-reset"
        render={props => <PasswordResetView {...props} />}
      />
      <Route
        path="/profile-page"
        render={props => <ProfileView {...props} />}
      />
    </Switch>
);