import React, { Component } from "react";
import classnames from "classnames";
import Firebase from './components/Firebase';
import bgLogoHeader from "../assets/img/square6.png";
import { toast } from 'react-toastify';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

export default class PasswordResetView extends Component {
  state = {};
  componentDidMount() {
    document.body.classList.toggle("index-page");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  };
  doPasswordReset() {
    //debugger;
    if (document.forms[0].checkValidity()) {
      var entries = Array.from(new FormData(document.forms[0]));
      new Firebase().doPasswordReset(entries[0][1]).then((user) => {
        toast.success('Your password has been reset. You\'ll receive an email to redefine it.', { autoClose: 10000, pauseOnHover: true });
        document.querySelector(`input[name='${Array.from(new FormData(document.forms[0]))[0][0]}']`).parentElement.classList.add('has-success');
      }).catch((error) => {
        toast.error(error.message, { autoClose: 10000, pauseOnHover: true });
        document.querySelector(`input[name='${Array.from(new FormData(document.forms[0]))[0][0]}']`).parentElement.classList.add('has-danger');
      });
    } else {
      for (const e of document.querySelectorAll('input'))
        if (!e.checkValidity()) {
          toast.error(`Invalid ${e.placeholder}: ${e.validationMessage}`, { autoClose: 10000, pauseOnHover: true });
          break;
        }
    }
  };
  onFormChange(e) {
    var btn = document.querySelector('.card-body > Button');
    if (document.forms[0].checkValidity()) {
      btn.classList.remove('disabled');
      btn.removeAttribute('disabled');
      btn.onclick = this.doPasswordReset.bind(this);
    } else {
      btn.classList.add('disabled');
      btn.setAttribute('disabled','');
      btn.removeAttribute('onClick');
    }
  };
  render() {
    return (
      <Row>
        <Col className="offset-md-2" lg="8" md="8">
          <Card className="card-register">
            <CardHeader>
              <CardImg alt="..." src={bgLogoHeader} className="passwordreset-header"/>
              <CardTitle tag="h4">Reset password</CardTitle>
            </CardHeader>
            <CardBody>
              <Form className="form">
                <InputGroup className={ classnames({"input-group-focus": this.state.emailFocus }) }>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="gray-icon tim-icons icon-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" name="email" required minLength="6" maxLength="150" onChange={e => this.onFormChange(e)} onFocus={ e => this.setState({ emailFocus: true }) } onBlur={ e => this.setState({ emailFocus: false }) } />
                </InputGroup>
              </Form>
              <Button className="btn" color="info" onClick={ e => this.doPasswordReset() } disabled>
                Reset
              </Button>
              <br/><br/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
