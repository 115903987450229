import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

const FirebaseContext = React.createContext();

// export class ContextProvider extends Component {
//     state = {
//         user: {},
//         boxes: []
//     }
  
//     setUser = (user) => {
//         this.setState((prevState) => ({ user }))
//     }
  
//     render() {
//         const { children } = this.props;
//         const { user, boxes } = this.state;
//         const { setUser } = this;

//         return (  
//         <FirebaseContext.Provider value={{ user, boxes, setUser }}>
//             {children}
//             <ToastContainer hideProgressBar={true}/>
//         </FirebaseContext.Provider>
//         )
//     }
// }

export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
      {firebase => <Component {...props} firebase={firebase} />}  
    </FirebaseContext.Consumer>
);
  
export const ContextConsumer = FirebaseContext.Consumer;

export default FirebaseContext;

