import firebase from 'firebase/app';
import 'firebase/auth';
//import 'firebase/firestore';
import 'firebase/database';



firebase.initializeApp({
    apiKey: 'AIzaSyCyEwetGchzgAXUekMGEqZe8m77Mrvuoh4',
    authDomain: 'webboter.com',
    databaseURL: 'https://webboter.firebaseio.com',
    projectId: 'webboter',
    storageBucket: 'webboter.appspot.com',
    messagingSenderId: '543426992435',
    measurementId: 'G-Q1R75PLR44',
    appId: '1:543426992435:web:a8fa96bf82228b3d058646'
});

class Firebase {
  constructor() {
    this.auth = firebase.auth();
    this.db = firebase.database();
    this.auth.settings.appVerificationDisabledForTesting = true;
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.githubProvider = new firebase.auth.GithubAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGithub = () =>
    this.auth.signInWithPopup(this.githubProvider);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => {
    this.facebookProvider.addScope('user_birthday');
    this.facebookProvider.setCustomParameters({ 'display':'popup' });
    this.auth.signInWithPopup(this.facebookProvider);
  }

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');
}

export default Firebase;