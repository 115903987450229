import React, { Component } from "react";
import classnames from "classnames";
import Firebase from './components/Firebase';
import bgLogoHeader from "../assets/img/square1.png";
import { toast } from 'react-toastify';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { isNullishCoalesce } from "typescript";

export default class SignUpView extends Component {
  state = { msgError: isNullishCoalesce };
  componentDidMount() {
    document.body.classList.toggle("index-page");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  };
  btnStatus() {
    return document.forms[0].checkValidity();
  };
  signUp(provider) {
    if (document.forms[0].checkValidity()) {
      var entries = Array.from(new FormData(document.forms[0]));
      if (entries[1][1] === entries[2][1]) {
        new Firebase().doCreateUserWithEmailAndPassword(entries[0][1], entries[1][1]).then((context) => {
          context.user.sendEmailVerification().then(() => {
            toast.success('We have sent you an e-mail validation.', {
              autoClose: 10000, 
              pauseOnHover: true,
              onClose: () => window.location.replace("/")
            });
            for (const e of document.querySelectorAll('input'))
              e.parentElement.classList.add('has-success');
          }).catch(err => {
            toast.error('Error trying to send email verification.', { autoClose: 10000, pauseOnHover: true });
            console.log(err.message);
          });
          document.forms[0].querySelectorAll('input').forEach((e) => e.parentElement.classList.remove('has-danger'));
          document.forms[0].reset();
          toast.dismiss();
          toast.success('Account successfully created.', { autoClose: 10000, pauseOnHover: true });
        }).catch((error) => {
          toast.error(error.message, { autoClose: 10000, pauseOnHover: true });
          if (['auth/email-already-exists','auth/email-already-in-use','auth/invalid-email'].includes(error.code))
            document.forms[0].querySelector('input[type=email]').parentElement.classList.add('has-danger');
        });
      } else {
        toast.error('Invalid password confirmation.', { autoClose: 10000, pauseOnHover: true });
        document.forms[0].querySelectorAll('input[type=password]').forEach((e) => e.parentElement.classList.add('has-danger'));
      } 
    } else {
      for (const e of document.querySelectorAll('input'))
        if (!e.checkValidity()) {
          toast.error(`Invalid ${e.placeholder}: ${e.validationMessage}`, { autoClose: 10000, pauseOnHover: true });
          break;
        }
    }
  };
  onFormChange(e) {
    var btn = document.querySelector('.card-body > Button');
    if (document.forms[0].checkValidity()) {
      btn.classList.remove('disabled');
      btn.removeAttribute('disabled');
      btn.onclick = this.signUp.bind(this);
    } else {
      btn.classList.add('disabled');
      btn.setAttribute('disabled','');
      btn.removeAttribute('onClick');
    }
  };
  render() {
    return (
      <Row>
        <Col className="offset-md-2" lg="8" md="8">
          <Card className="card-register">
            <CardHeader>
              <CardImg alt="..." src={bgLogoHeader} className="signup-header"/>
              <CardTitle tag="h4">Sign Up</CardTitle>
            </CardHeader>
            <CardBody>
              <Form className="form" >
                <InputGroup className={classnames({ "input-group-focus": this.state.emailFocus })}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="gray-icon tim-icons icon-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" name="email" required minLength="6" maxLength="150" onChange={e => this.onFormChange(e)} onFocus={e => this.setState({ emailFocus: true })} onBlur={e => this.setState({ emailFocus: false })}/>
                </InputGroup>
                <InputGroup className={classnames({ "input-group-focus": this.state.passwordFocus })}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="gray-icon tim-icons icon-lock-circle" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" name="password" required pattern="^\S{6,}$" onChange={e => this.onFormChange(e)} onFocus={e => this.setState({ passwordFocus: true })} onBlur={e => this.setState({ passwordFocus: false })}/>
                </InputGroup>
                <InputGroup className={classnames({ "input-group-focus": this.state.passwordConfirmationFocus })}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="gray-icon tim-icons icon-lock-circle" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password Confirmation" type="password" name="password_confirm" required pattern="^\S{6,}$" onChange={e => this.onFormChange(e)} onFocus={e => this.setState({ passwordConfirmationFocus: true }) } onBlur={e => this.setState({ passwordConfirmationFocus: false })}/>
                </InputGroup> 
                <FormGroup check className="text-left">
                  <Label check>
                    <Input type="checkbox" required onChange={e => this.onFormChange(e)}/>
                    <span className="form-check-sign" />I agree to the{" "}<a href="#pablo" onClick={e => e.preventDefault()}>terms and conditions</a>.
                  </Label>
                </FormGroup>
              </Form>
              <br/>
              <Button className="btn" color="info" disabled>
                Sign Up
              </Button>
              <br/><br/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };
}




// // reactstrap components


// // core components
// import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
// import Footer from "../../components/Footer/Footer.js";

// class RegisterPage extends React.Component {
//   state = {
//     squares1to6: "",
//     squares7and8: ""
//   };
//   componentDidMount() {
//     document.body.classList.toggle("register-page");
//     document.documentElement.addEventListener("mousemove", this.followCursor);
//   }
//   componentWillUnmount() {
//     document.body.classList.toggle("register-page");
//     document.documentElement.removeEventListener(
//       "mousemove",
//       this.followCursor
//     );
//   }
//   followCursor = event => {
//     let posX = event.clientX - window.innerWidth / 2;
//     let posY = event.clientY - window.innerWidth / 6;
//     this.setState({
//       squares1to6:
//         "perspective(500px) rotateY(" +
//         posX * 0.05 +
//         "deg) rotateX(" +
//         posY * -0.05 +
//         "deg)",
//       squares7and8:
//         "perspective(500px) rotateY(" +
//         posX * 0.02 +
//         "deg) rotateX(" +
//         posY * -0.02 +
//         "deg)"
//     });
//   };
//   render() {
//     return (
//       <>
//         <ExamplesNavbar />
//         <div className="wrapper">
//           <div className="page-header">
//             <div className="page-header-image" />
//             <div className="content">

//             </div>
//           </div>
//           <Footer />
//         </div>
//       </>
//     );
//   }
// }

// export default RegisterPage;
