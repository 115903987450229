import React, { Component } from "react";
import classnames from "classnames";
import Firebase from './components/Firebase';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import bgLogoHeader from "../assets/img/square6.png";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

export default class SignInView extends Component {
  state = {};
  componentDidMount() {
    document.body.classList.toggle("index-page");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  };
  doPasswordReset() {
    window.location.replace("/password-reset");
  };
  doSignIn(provider) {
    switch (provider) {
      case 'FACEBOOK':
        new Firebase().doSignInWithFacebook(); 
        break;
      case 'GOOGLE':
        new Firebase().doSignInWithGoogle();
        break;
      case 'GITHUB':
        new Firebase().doSignInWithGithub();
        break;
      default:
        if (document.forms[0].checkValidity()) {
          var entries = Array.from(new FormData(document.forms[0]));
          new Firebase().doSignInWithEmailAndPassword(entries[0][1],entries[1][1]).then(() => {
            debugger;
          }).catch((error) => {
            toast.error(error.message, { autoClose: 10000, pauseOnHover: true });
            for (const e of document.querySelectorAll('input'))
              e.parentElement.classList.add('has-danger');
          });
        } else {
          for (const e of document.querySelectorAll('input'))
            if (!e.checkValidity()) {
              this.setState({...this.state, msgError:`Invalid ${e.placeholder}: ${e.validationMessage}`});
              e.parentElement.classList.add('has-danger');
              break;
            }
        }
    }
  };
  onFormChange(e) {
    var btn = document.querySelector('.card-body > Button');
    if (document.forms[0].checkValidity()) {
      btn.classList.remove('disabled');
      btn.removeAttribute('disabled');
      btn.onclick = this.doSignIn.bind(this);
    } else {
      btn.classList.add('disabled');
      btn.setAttribute('disabled','');
      btn.removeAttribute('onClick');
    }
  };
  render() {
    return (
      <Row>
        <Col className="offset-md-2" lg="8" md="8">
          <Card className="card-register">
            <CardHeader>
              <CardImg alt="..." src={bgLogoHeader} className="signin-header"/>
              <CardTitle tag="h4">Sign In</CardTitle>
            </CardHeader>
            <CardBody>
              <Form className="form">
                <InputGroup className={ classnames({"input-group-focus": this.state.emailFocus }) }>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="gray-icon tim-icons icon-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" name="email" required minLength="6" maxLength="150" onChange={e => this.onFormChange(e)} onFocus={ e => this.setState({ emailFocus: true }) } onBlur={ e => this.setState({ emailFocus: false }) } />
                </InputGroup>
                <InputGroup className={ classnames({ "input-group-focus": this.state.passwordFocus }) }>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="gray-icon tim-icons icon-lock-circle" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" name="password" required pattern="^\S{6,}$" onChange={e => this.onFormChange(e)} onFocus={ e => this.setState({ passwordFocus: true }) } onBlur={ e => this.setState({ passwordFocus: false }) } />
                </InputGroup>
                <FormGroup className="text-left">
                  <Label>
                    <span className="form-check-sign" />Forgot your password? &nbsp; <a href="#pablo" onClick={ e => this.doPasswordReset() }>Retrieve it here</a>.
                  </Label>
                </FormGroup>
              </Form>
              <Button className="btn" color="info" onClick={e => this.doSignIn('EMAIL')} disabled>
                Sign In
              </Button>
              <br/>
              <div className="gray-icon text-center category mb-4 mt-3 btn-toolbar">
                <hr className="line-deafult width-40"/>
                <div className="width-20">or sign in with </div>
                <hr className="line-deafult width-40"/>
              </div>
              <br/>
              <div className="btn-wrapper text-center">
                <Button className="btn-round width-80" color="facebook" onClick={e => this.doSignIn('FACEBOOK')}>
                  <i className="fab fa-facebook"></i> &nbsp; Facebook account
                </Button>
                <Button className="btn-round width-80" color="github" onClick={e => this.doSignIn('GITHUB')}>
                  <i className="fab fa-github"></i> &nbsp; GitHub account
                </Button>
                <Button className="btn-round width-80" color="google-plus" onClick={e => this.doSignIn('GOOGLE')}>
                  <i className="fab fa-google"></i> &nbsp; Google account
                </Button>
              </div>
              <br/><br/>
              <div className="gray-icon text-center category mb-4 mt-3 btn-toolbar">
                <hr className="line-deafult width-35"/>
                <div className="width-30">Don't have an account? </div>
                <hr className="line-deafult width-35"/>
              </div>
              <br/>
              <div className="btn-wrapper text-center">
                <Button className="btn-round width-80" color="default" to="/sign-up" tag={Link}>
                  <i className="fab fa-user"></i> &nbsp; Sign up
                </Button>
              </div>
              <br/><br/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}




// // reactstrap components


// // core components
// import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
// import Footer from "../../components/Footer/Footer.js";

// class RegisterPage extends React.Component {
//   state = {
//     squares1to6: "",
//     squares7and8: ""
//   };
//   componentDidMount() {
//     document.body.classList.toggle("register-page");
//     document.documentElement.addEventListener("mousemove", this.followCursor);
//   }
//   componentWillUnmount() {
//     document.body.classList.toggle("register-page");
//     document.documentElement.removeEventListener(
//       "mousemove",
//       this.followCursor
//     );
//   }
//   followCursor = event => {
//     let posX = event.clientX - window.innerWidth / 2;
//     let posY = event.clientY - window.innerWidth / 6;
//     this.setState({
//       squares1to6:
//         "perspective(500px) rotateY(" +
//         posX * 0.05 +
//         "deg) rotateX(" +
//         posY * -0.05 +
//         "deg)",
//       squares7and8:
//         "perspective(500px) rotateY(" +
//         posX * 0.02 +
//         "deg) rotateX(" +
//         posY * -0.02 +
//         "deg)"
//     });
//   };
//   render() {
//     return (
//       <>
//         <ExamplesNavbar />
//         <div className="wrapper">
//           <div className="page-header">
//             <div className="page-header-image" />
//             <div className="content">

//             </div>
//           </div>
//           <Footer />
//         </div>
//       </>
//     );
//   }
// }

// export default RegisterPage;
