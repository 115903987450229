import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <h1 className="title">web•boter</h1>
            </Col>
            <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/register-page" tag={Link}>
                    Register
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/profile-page" tag={Link}>
                    Profile
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink href="/" >
                    Contact Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/" >
                    About Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/" >
                    Blog
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3">
              <h3 className="title">Follow us</h3>
              <div className="btn-wrapper profile">
                <Button
                  className="btn-icon btn-round btn-twitter"
                  color="default"
                  href="https://twitter.com/webboter"
                  // id="tooltip622135962"
                  target="_blank"
                >
                  <i className="fab fa-twitter" />
                </Button>
                {/* <UncontrolledTooltip delay={0} target="tooltip622135962">
                  Follow us
                </UncontrolledTooltip> */}
                <Button
                  className="btn-icon btn-round btn-facebook"
                  color="default"
                  href="https://www.facebook.com/webboter.webautomation"
                  // id="tooltip230450801"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                {/* <UncontrolledTooltip delay={0} target="tooltip230450801">
                  Like us
                </UncontrolledTooltip> */}
                <Button
                  className="btn-icon btn-round btn-instagram"
                  color="default"
                  href="https://www.instagram.com/webboter"
                  // id="tooltip318450378"
                  target="_blank"
                >
                  <i className="fab fa-instagram" />
                </Button>
                {/* <UncontrolledTooltip delay={0} target="tooltip318450378">
                  Follow us
                </UncontrolledTooltip> */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
