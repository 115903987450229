import React from "react";

// sections for this page/view
// import Basics from "./IndexSections/Basics.js";
// import Navbars from "./IndexSections/Navbars.js";
// import Tabs from "./IndexSections/Tabs.js";
// import Pagination from "./IndexSections/Pagination.js";
// import Notifications from "./IndexSections/Notifications.js";
// import Typography from "./IndexSections/Typography.js";
// import JavaScript from "./IndexSections/JavaScript.js";
// import NucleoIcons from "./IndexSections/NucleoIcons.js";
// import Signup from "./IndexSections/Signup.js";
// import Examples from "./IndexSections/Examples.js";
// import Download from "./IndexSections/Download.js";


export default class HomeView extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("index-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {
    return (
      <>
        {/* <Basics />
        <Navbars />
        <Tabs />
        <Pagination />
        <Notifications />
        <Typography />
        <JavaScript />
        <NucleoIcons />
        <Signup />
        <Examples />
        <Download /> */}  
      </>
    );
  }
}
